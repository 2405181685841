import React, { Suspense, lazy } from 'react';
import { Element } from 'react-scroll';
import MenuBar from '../components/menuBarMobile/MenuBar';
import Skeleton from '../components/skeleton/skeletonMobile';

const ImageCarousel = lazy(() => import('./Carousel/carousel'));
const AboutUs = lazy(() => import('./AboutUs.js/aboutUs'));
const Gallery = lazy(() => import('./Gallery/gallery'));
const MainBrands = lazy(() => import('./MainBrands/mainBrands'));
const OurHighlights = lazy(() => import('./OurHighlights/ourHighlights'));
const ContactForm = lazy(() => import('./ContactForm/contactForm'));
const Contact = lazy(() => import('./Contact/contact'));
const Footer = lazy(() => import('./Footer/footer'));

const MobilePages = () => {
  return (
    <>
      <MenuBar />
      <Suspense fallback={<Skeleton />}>
        <Element name="main">
          <ImageCarousel />
        </Element>
        <AboutUs />
        <Element name="gallery">
          <Gallery />
        </Element>
        <Element name="mainBrands">
          <MainBrands />
        </Element>
        <OurHighlights />
        <Element name="contactForm">
          <ContactForm />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
        <Footer />
      </Suspense>
    </>
  );
};

export default MobilePages;
