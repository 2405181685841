import React, { useState } from 'react';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Card, TextField, Button, Checkbox, FormControlLabel, Modal, Box, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './underConstrucion.css';import { db } from '../../firebase/firebaseConfig';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import newsletterPhoto from '../../assets/images/newsletter/newsletterPhoto.png';
const UnderConstruction = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: '', email: '', acceptPolicy: false });
  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openNewsletterModal, setOpenNewsletterModal] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({ ...prev, [name]: name === 'acceptPolicy' ? checked : value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Imię jest wymagane';
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'E-mail jest wymagany';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Nieprawidłowy adres e-mail';
      isValid = false;
    }

    if (!formData.acceptPolicy) {
      newErrors.acceptPolicy = 'Musisz zaakceptować politykę prywatności';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        // Check if the email already exists in the database
        const emailQuery = query(collection(db, "newsletter"), where("email", "==", formData.email));
        const querySnapshot = await getDocs(emailQuery);
  
        if (!querySnapshot.empty) {
          // Email already exists
          setErrors(prev => ({ ...prev, email: 'Ten adres email jest już zarejestrowany do newslettera' }));
        } else {
          // Email doesn't exist, proceed with adding to the database
          await addDoc(collection(db, "newsletter"), {
            name: formData.name,
            email: formData.email,
            acceptPolicy: formData.acceptPolicy,
            createdAt: new Date()
          });
          console.log('Form submitted:', formData);
          setOpenNewsletterModal(true);  // Show confirmation modal
        }
      } catch (error) {
        console.error("Błąd przy sprawdzaniu lub dodawaniu danych do Firestore: ", error);
      }
    }
  };
  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseNewsletterModal = () => {
    setOpenNewsletterModal(false);
  };

  return (
    <div className="under-construction" role="main">
      <div className="content-wrapper">
        <motion.div className="construction-animation" aria-hidden="true">
          <motion.div
            className="gear"
            animate={{
              rotate: 360,
              transition: { duration: 8, repeat: Infinity, ease: "linear" }
            }}
          />
        </motion.div>
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Strona w trakcie prac
        </motion.h1>
        <motion.button
          className="go-back-button mt-3 font-semibold"
          onClick={handleGoBack}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          aria-label="Powrót do poprzedniej strony"
        >
          Powrót
        </motion.button>
      </div>

      <div className="newsletter-section">
        <h2>Nie chcesz żeby coś Cię ominęło? Zapisz się do newslettera</h2>
        <Card className="newsletter-card">
          <form onSubmit={handleSubmit} aria-label="Formularz zapisu do newslettera">
            <TextField
              name="name"
              label="IMIĘ"
              variant="standard"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              InputProps={{
                endAdornment: <FiberManualRecordIcon sx={{ color: '#686868', fontSize: 'small' }} aria-hidden="true" />,
              }}
              aria-required="true"
              aria-invalid={!!errors.name}
            />
            <TextField
              name="email"
              label="E-MAIL"
              variant="standard"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              InputProps={{
                endAdornment: <FiberManualRecordIcon sx={{ color: '#686868', fontSize: 'small' }} aria-hidden="true" />,
              }}
              aria-required="true"
              aria-invalid={!!errors.email}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptPolicy"
                  checked={formData.acceptPolicy}
                  onChange={handleChange}
                />
              }
              label="AKCEPTUJĘ I ROZUMIEM POLITYKĘ PRYWATNOŚCI"
              aria-required="true"
              aria-invalid={!!errors.acceptPolicy}
            />

            {errors.acceptPolicy && (
              <p className="error-message" role="alert">
                <ErrorOutlineIcon sx={{ color: '#ED1C24', marginRight: '5px', verticalAlign: 'middle' }} aria-hidden="true" />
                {errors.acceptPolicy}
              </p>
            )}
            <Button 
              variant="contained" 
              sx={{ backgroundColor: '#ED1C24', px: 10, borderRadius: '10px' }}
              type="submit"
              aria-label="Wyślij formularz"
            >
              WYŚLIJ
            </Button>
          </form>
        </Card>
      </div>

      <div className="privacy-policy-link">
        <Button onClick={handleOpenModal} aria-haspopup="dialog">Polityka prywatności</Button>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh',
          overflow: 'auto',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Polityka prywatności
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            1. Informacje ogólne<br/>
            Niniejsza polityka prywatności określa zasady przetwarzania i ochrony danych osobowych, zbieranych od użytkowników za pośrednictwem strony internetowej tawkor.pl. Gromadzone są tylko dane osobowe w postaci imienia i adresu e-mail.<br/><br/>

            2. Administrator danych osobowych<br/>
            Administratorem danych osobowych jest Firma TAW-KOR Marta Kornaus, z siedzibą w Wola Rzędzińska, adres e-mail: tawkor@tawkor.pl.<br/><br/>

            3. Zakres zbieranych danych<br/>
            Zbierane dane osobowe to:<br/>
            • Imię: w celu personalizacji komunikacji.<br/>
            • Adres e-mail: w celu wysyłania wiadomości, odpowiadania na zapytania oraz przesyłania materiałów marketingowych (np. newslettera), o ile użytkownik wyraził na to zgodę.<br/><br/>

            4. Cele przetwarzania danych<br/>
            Zebrane dane są przetwarzane wyłącznie w następujących celach:<br/>
            • Utrzymywanie kontaktu z użytkownikami (np. odpowiedzi na pytania).<br/>
            • Przesyłanie materiałów marketingowych i promocyjnych, w tym newslettera (jeśli użytkownik wyraził zgodę na jego otrzymywanie).<br/><br/>

            5. Podstawa prawna przetwarzania danych<br/>
            Przetwarzanie danych osobowych odbywa się zgodnie z:<br/>
            • Art. 6 ust. 1 lit. a Rozporządzenia RODO – zgoda użytkownika na przetwarzanie danych osobowych.<br/><br/>

            6. Udostępnianie danych osobowych<br/>
            Dane osobowe użytkowników nie są sprzedawane, ani udostępniane osobom trzecim, chyba że wymagają tego przepisy prawa.<br/><br/>

            7. Okres przechowywania danych<br/>
            Dane osobowe będą przechowywane do momentu, gdy użytkownik wycofa swoją zgodę na ich przetwarzanie lub gdy przestaną być one potrzebne do realizacji celów, dla których zostały zebrane.<br/><br/>

            8. Prawa użytkownika<br/>
            Użytkownik ma prawo do:<br/>
            • Dostępu do swoich danych osobowych.<br/>
            • Sprostowania danych osobowych.<br/>
            • Usunięcia danych („prawo do bycia zapomnianym").<br/>
            • Ograniczenia przetwarzania danych.<br/>
            • Wniesienia sprzeciwu wobec przetwarzania danych.<br/>
            • Wycofania zgody na przetwarzanie danych w dowolnym momencie, co nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.<br/><br/>

            W celu realizacji powyższych praw należy skontaktować się z administratorem danych pod adresem: tawkor@tawkor.pl.<br/><br/>

            9. Zmiany w polityce prywatności<br/>
            Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszej polityce prywatności. Wszelkie zmiany będą publikowane na tej stronie.<br/><br/>

            10. Kontakt<br/>
            W przypadku pytań dotyczących polityki prywatności prosimy o kontakt pod adresem e-mail: tawkor@tawkor.pl.<br/><br/>

            Data aktualizacji: 24.09.2024
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openNewsletterModal}
        onClose={handleCloseNewsletterModal}
        aria-labelledby="newsletter-modal-title"
        aria-describedby="newsletter-modal-description"
      >
        {isMobile ? (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pb: 2,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>

            <Box sx={{ position: 'relative', width: '100%' }}>
              <img
                src={newsletterPhoto}
                alt="Panoramiczne zdjęcie powitalne"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Typography
                id="newsletter-modal-title"
                variant="h6"
                component="h3"
                align="center"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.8)',
                  width: '100%',// Maksymalna szerokość dla zachowania czytelności
                }}
              >
                Witamy w naszym newsletterze!
              </Typography>
            </Box>
            <Typography id="newsletter-modal-description" variant="body2" align="left" sx={{ mb: 3, ml: 2, mt: 2 }}>
              Dziękujemy za dołączenie do naszej społeczności. Będziemy regularnie przesyłać Ci najnowsze informacje i ekskluzywne oferty.
            </Typography>
            <Button 
              variant="contained" 
              onClick={handleCloseNewsletterModal} 
              sx={{ 
                backgroundColor: '#ED1C24', 
                px: 5, 
                py: 1,
                borderRadius: '10px',
              }}
            >
              Zamknij
            </Button>
          </Box>
        ) : (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 0,
            maxHeight: '80vh',
            overflow: 'hidden',
            position: 'relative',
          }}>
            <img
              src={newsletterPhoto}
              alt="Panoramiczne zdjęcie powitalne"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              padding: 2,
            }}>
              <Typography id="newsletter-modal-title" variant="h4" component="h2" align="center">
                Witamy w naszym newsletterze!
              </Typography>
              <Typography id="newsletter-modal-description" sx={{ mt: 2 }} align="center">
                Dziękujemy za dołączenie do naszej społeczności. Będziemy regularnie przesyłać Ci najnowsze informacje i ekskluzywne oferty.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Button variant="contained" onClick={handleCloseNewsletterModal} sx={{ backgroundColor: '#ED1C24', px: 10, borderRadius: '10px' }}>
                  Zamknij
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default UnderConstruction;