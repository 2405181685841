// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQutoLLRmLHXN_Lz1rW6YNKQHXklqDN8Y",
  authDomain: "taw-kor.firebaseapp.com",
  projectId: "taw-kor",
  storageBucket: "taw-kor.appspot.com",
  messagingSenderId: "714893397693",
  appId: "1:714893397693:web:51df08742e29c62648b1d4",
  measurementId: "G-G2KVELMFJ9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export {db};   