import React from 'react';
import styled from 'styled-components';

const SkeletonPulse = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const SkeletonLine = styled(SkeletonPulse)`
  width: 100%;
  height: ${props => props.height || '15px'};
  margin-bottom: 10px;
  border-radius: 4px;
`;

const SkeletonWrapper = styled.div`
  padding: 20px;
  background: white;
  border-radius: 4px;
`;

const Skeleton = () => (
  <SkeletonWrapper>
    <SkeletonLine height="70vh" />
    <SkeletonLine height="3vh" />
    <SkeletonLine height="3vh" />
    <SkeletonLine height="3vh" />
  </SkeletonWrapper>
);

export default Skeleton;
