import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import logo from '../assets/images/logo.png';
import logoDark from '../assets/images/logoDark.png';
import './MenuBar.css';
import Divider from '@mui/material/Divider';
import { useTheme } from '../context/ThemeContext';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import useWindowWidth from '../hooks/useWindowWidth';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#ED1C24',
        opacity: 1,
        border: '2px solid #fff', // White border when switched on
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff', // White dot when switched on
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    backgroundColor: '#000', // Black dot when switched off
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    border: '2px solid #000', // Black border when switched off
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 500,
    }),
  },
}));
const MenuBar = () => {  
  const navigate = useNavigate();

  const windowWidth = useWindowWidth();

  const [isSticky, setIsSticky] = useState(false);
  const { darkMode, toggleDarkMode } = useTheme();

  const getSpacing = () => {
    if (windowWidth > 1400) return 'space-x-20';
    if (windowWidth > 1200) return 'space-x-10';
    if (windowWidth > 1000) return 'space-x-8';
    if (windowWidth > 800) return 'space-x-6';
    return 'space-x-2';
  };

  const handleShopClick = () => {
    navigate('/under-construction');
  };
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menuItems = [
    { label: 'SKLEP INTERNETOWY', action: handleShopClick, type: 'action' },
    { label: 'GALERIA', to: 'gallery', type: 'scroll' },
    { label: 'MARKI WIODĄCE', to: 'mainBrands', type: 'scroll' },
    { label: 'WSPÓŁPRACA', to: 'contactForm', type: 'scroll' },
    { label: 'KONTAKT', to: 'contact', type: 'scroll' },
  ];

  return (
    <div className={`menu-bar flex flex-col p-0 ${isSticky ? 'sticky' : ''} ${darkMode ? 'bg-[#303030] text-white' : 'bg-white'}`} role="navigation">
      <div className="flex items-center p-2">
        <img alt="Logo firmy" src={darkMode ? logoDark : logo} height="40" className="ml-20 mr-10" />
        <nav className={`flex-1 flex justify-center items-center ${getSpacing()}`}>
          {menuItems.map((item, index) => (
            item.type === 'action' ? (
              <div key={index} onClick={item.action} className="menu-item text-sm font-semibold cursor-pointer" role="button" tabIndex="0" aria-label={item.label}>
                {item.label}
              </div>
            ) : item.type === 'scroll' ? (
              <ScrollLink key={index} to={item.to} smooth={true} duration={500} className="menu-item text-sm font-semibold cursor-pointer" role="link" tabIndex="0" aria-label={`Przejdź do sekcji ${item.label}`}>
                {item.label}
              </ScrollLink>
            ) : (
              <Link key={index} to={item.path} className="menu-item text-sm font-semibold" role="link" aria-label={`Przejdź do ${item.label}`}>
                {item.label}
              </Link>
            )
          ))}
        </nav>
        <div className="flex flex-col items-center mx-10">
          <i 
            className={`pi ${darkMode ? 'pi-moon icon-moon' : 'pi-sun icon-sun'} icon-transition mb-2 text-2xl ${darkMode ? 'text-white' : ''}`}
            aria-hidden="true"
          ></i>
          <IOSSwitch 
            checked={darkMode} 
            onChange={toggleDarkMode} 
            aria-label={darkMode ? "Przełącz na tryb jasny" : "Przełącz na tryb ciemny"}
          />
        </div>
      </div>
      <Divider className='bg-red-500 h-2 mt-2 mb-2' role="separator" />
    </div>
  );
};
export default MenuBar;