import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import logo from '../../assets/images/logo.png';
import logoDark from '../../assets/images/logoDark.png';
import './MenuBar.css';
import Divider from '@mui/material/Divider';
import { useTheme } from '../../context/ThemeContext';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#ED1C24',
        opacity: 1,
        border: '2px solid #fff',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    backgroundColor: '#000',
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    border: '2px solid #000',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 500,
    }),
  },
}));

const MenuBar = () => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const { darkMode, toggleDarkMode } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showUnderConstruction, setShowUnderConstruction] = useState(false);

  const handleShopClick = () => {
    navigate('/under-construction');
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menuItems = [
    { label: 'SKLEP INTERNETOWY', path: '/' },
    { label: 'GALERIA', to: 'gallery' },
    { label: 'MARKI WIODĄCE', to: 'mainBrands' },
    { label: 'WSPÓŁPRACA', to: 'contactForm' },
    { label: 'KONTAKT', to: 'contact' },
  ];
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => {
      const newMenuState = !prevState;
      document.body.style.overflow = newMenuState ? 'hidden' : 'auto';
      return newMenuState;
    });
  };
  
  const handleLinkClick = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';  // Przywracamy przewijanie
  };
  
  return (
    <div className={`menu-bar flex flex-col p-0 ${isSticky ? 'sticky' : ''} ${darkMode ? 'bg-[#303030] text-white dark-mode' : 'bg-white light-mode'}`} role="navigation" aria-label="Menu główne">
      <div className="flex items-center justify-between p-2">
        <ScrollLink to="main" smooth={true} duration={500}>
          <img alt="Logo firmy" src={darkMode ? logoDark : logo} height="40" className="ml-4 cursor-pointer" />
        </ScrollLink>
        <button className={`menu-icon mr-4 mb-2 ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu} aria-expanded={isMenuOpen} aria-label="Przełącz menu">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </button>
      </div>
      <Divider className='bg-red-500 h-2 mt-2 mb-2' role="separator" />
      {isMenuOpen && (
        <div className="menu-content open" role="menu">
          <nav className="flex flex-col items-start">
            {menuItems.map((item, index) => (
              item.label === 'SKLEP INTERNETOWY' ? (
                <div
                  key={index}
                  className="menu-item text-2xl font-bold py-4 pl-4 w-full cursor-pointer"
                  onClick={handleShopClick}
                  role="menuitem"
                  tabIndex="0"
                >
                  {item.label}
                </div>
              ) : (
                item.to ? (
                  <ScrollLink 
                    key={index}
                    to={item.to}
                    className="menu-item text-2xl font-bold py-4 pl-4 w-full"
                    smooth={true}
                    duration={500}
                    onClick={handleLinkClick}
                    role="menuitem"
                    tabIndex="0"
                  >
                    {item.label}
                  </ScrollLink>
                ) : (
                  <Link 
                    key={index} 
                    to={item.path} 
                    className="menu-item text-2xl font-bold py-4 pl-4 w-full" 
                    onClick={handleLinkClick}
                    role="menuitem"
                    tabIndex="0"
                  >
                    {item.label}
                  </Link>
                )
              )
            ))}
            <div className="flex flex-row items-center pl-4" 
              onClick={toggleDarkMode}
              role="menuitem"
              tabIndex="0"
              aria-label="Przełącz motyw strony">
              <p className="menu-item text-2xl font-bold py-4 mr-2">MOTYW STRONY</p>
              <i 
                className={`pi ${darkMode ? 'pi-moon icon-moon' : 'pi-sun icon-sun'} icon-transition ml-2 text-4xl ${darkMode ? 'text-white' : ''}`}
                aria-hidden="true"
              ></i>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default MenuBar;