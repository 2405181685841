import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext'
import MenuBar from './components/MenuBar';
import './App.css';
import { useMediaQuery } from 'react-responsive';
import DesktopPages from './pages/DesktopPages';
import MobilePages from './pagesMobile/MobilePages';
import UnderConstruction from './pagesMobile/shop/underConstrucion';

function App() {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/under-construction" element={<UnderConstruction />} />
          {isDesktopOrLaptop && (
            <Route path="/*" element={
              <div className="content-container">
                <DesktopPages />
              </div>
            } />
          )}
          {isTablet && (
            <Route path="/*" element={
              <div className="content-container">
                <MobilePages />
              </div>
            } />
          )}
          {isMobile && (
            <Route path="/*" element={
              <div className="content-container">
                <MobilePages />
              </div>
            } />
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;